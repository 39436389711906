export function $extractDomain(url) {
 var hostname;
 if (process.env.NODE_ENV === 'development') {
  // return 'phonetictext'
  // return 'redozo' //文字转语音
  return 'animemagic' //积分
  // return 'pdfartisancraft' //pdf
 }
 // 移除协议（如 http://, https://）
 if (url.indexOf("//") > -1) {
  hostname = url.split('/')[2];
 } else {
  hostname = url.split('/')[0];
 }

 // 移除端口号
 hostname = hostname.split(':')[0];

 // 移除查询字符串
 hostname = hostname.split('?')[0];

 // 找到"."字符并从最后一个点切割
 var parts = hostname.split('.').reverse();
 const websiteConfig = {
  vox2txt: { domain: 'vox2txt.net', id: 'vox2txtnet' },
  ctoolpro: { domain: 'ctool.pro', id: 'ctoolpro' },
  oldpixvip: { domain: 'oldpix.cc', id: 'oldpixvip' },
  animpiccc: { domain: 'animpic.cc', id: 'animpiccc' },
  oldpixcc: { domain: 'oldpix.cc', id: 'oldpixcc' },
 };
 for (let key in websiteConfig) {
  if (window.location.origin.includes(websiteConfig[key].domain)) {
   return websiteConfig[key].id;
  }
 }
 if (parts != null && parts.length > 1) {
  // country code TLD (ccTLD) (如: .uk, .es 等)
  if (parts[1].length == 2 && parts.length > 2) {
   return parts[2];
  } else {
   return parts[1];
  }
 }
 return url;
}